import { createContext } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface HeaderContextIf {
  title: string | undefined;
  subtitle: string | undefined;
  image: IGatsbyImageData | undefined;
  height: number | undefined;
  previousHeight: number | undefined;

  setContext: ({
    title,
    subtitle,
    image,
    height,
  }: Partial<HeaderContextIf>) => void;
}

export const headerContextDefault: HeaderContextIf = {
  title: '',
  subtitle: '',
  image: undefined,
  height: 0,
  previousHeight: 0,
  setContext: ({ title, subtitle, image, height }) => {
    headerContextDefault.title = title;
    headerContextDefault.subtitle = subtitle;
    headerContextDefault.image = image;
    headerContextDefault.previousHeight = headerContextDefault.height;
    headerContextDefault.height = height || 0;
  },
};

export const HeaderContext =
  createContext<HeaderContextIf>(headerContextDefault);
