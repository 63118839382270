import { initializeApp, FirebaseApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAddnls-rJC_yd_PkdY-L4cN3OlP3mw7uw',
  authDomain: 'bartusiak-homepage.firebaseapp.com',
  databaseURL:
    'https://bartusiak-homepage-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bartusiak-homepage',
  storageBucket: 'bartusiak-homepage.appspot.com',
  messagingSenderId: '463101450399',
  appId: '1:463101450399:web:1360c9f2d0cf7c0d056aaa',
  measurementId: 'G-TVL8CZ2NK1',
};

export const firebaseApp: FirebaseApp | undefined =
  typeof window !== 'undefined' ? initializeApp(firebaseConfig) : undefined;
