import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/open-sans/index.css';

import './src/utils/init-firebase';

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import {
  HeaderContext,
  headerContextDefault,
} from './src/contexts/header-context';
import { blogTheme } from './src/styles/theme';

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

export const wrapRootElement = ({ element }) => (
  <MuiThemeProvider theme={blogTheme}>
    <CssBaseline>
      <HeaderContext.Provider value={headerContextDefault}>
        {element}
      </HeaderContext.Provider>
    </CssBaseline>
  </MuiThemeProvider>
);
