import { createTheme } from '@material-ui/core';

export const blogTheme = createTheme({
  palette: {
    primary: {
      light: '#FFCCBC',
      main: '#FF5722',
      dark: '#E64A19',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#536DFE',
      contrastText: '#757575',
    },
    green: {
      main: '#cddc39',
      contrastText: '#827717',
    },
    lightGrey: {
      main: '#DDD',
      contrastText: '#444',
    },
    dark: {
      main: '#343a40',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: `'Open Sans', 'Roboto', 'Montserrat', serif!important`,
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      lineHeight: 1.5,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      lineHeight: 1.4,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      lineHeight: 1.3,
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      lineHeight: 1.535,
    },
  },
});
